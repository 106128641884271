import _ from 'lodash'

export function useLayoutMenu () {
  const getLevel = (obj, items) => {
    let level = 0
    try {
      do {
        level++
      } while (_.uniq(_.map(items, i => {
        return i.split('/')[level]
      })).length === 1)
    } catch {
    }

    const taking = _.take(obj.split('/'), level + 1)
    return _.reduce(taking, (memo, val) => {
      return memo + val
    }, '')
  }

  const isActiveItem = (routePath, destination, menuItems) => {
    return getLevel(routePath, menuItems) === getLevel(destination, menuItems)
  }

  return { isActiveItem }
}
