import PimFetcher from '@/services/fetchers/Pim'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import _ from 'lodash'

export const usePimStore = defineStore('pim', () => {
  const references = ref([])

  const getReferences = computed(() => references.value)
  const getReferencesByKey = computed(() => key => {
    return references.value?.filter(reference => reference.key === key) ?? []
  })

  function addReferences (payload) {
    const newRefs = references.value ? [...payload, ...this.references] : [...payload]
    references.value = _.uniqBy(newRefs, r => [r.value, r.key])
  }
  function clear () {
    references.value = null
  }
  async function fetchReferences (payload) {
    const currentStateReferences = references.value || []
    const valuesToFetch = payload.values?.filter(v => {
      const existingKey = currentStateReferences.find(r => r.key === payload.key && r.value === v)
      return v && !existingKey
    }) ?? []
    if (valuesToFetch.length) {
      const results = (await PimFetcher.get(payload.slug, { key: payload.key, values: valuesToFetch })).data.data
      let newStateReferences = _.cloneDeep(references.value || [])
      newStateReferences = _.uniqBy(newStateReferences.concat(results), r => [r.value, r.key])
      references.value = newStateReferences
    }
  }

  return {
    references,
    getReferences,
    getReferencesByKey,
    addReferences,
    clear,
    fetchReferences
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(usePimStore, import.meta.webpackHot))
}
