import qs from 'qs'
import _ from 'lodash'
import axios from '@/plugins/axios'
import EntityTypes from '@/constants/EntityTypes'
import jsonToFormData from '@/services/jsonToFormData'
import objectRemoveEmpty from '@/services/objectRemoveEmpty'

const endpoints = {
  [EntityTypes.Channel]: 'channels',
  [EntityTypes.Product]: 'products',
  [EntityTypes.Project]: 'projects',
  [EntityTypes.Answer]: 'answers',
  [EntityTypes.Team]: 'teams',
  [EntityTypes.Retailer]: 'companies/retailers',
  [EntityTypes.Applicant]: 'users/applicants',
  [EntityTypes.Collaborator]: 'users/retailers',
  [EntityTypes.Facilitator]: 'users/facilitators'
}

const userExtraParams = { withProfile: 1, withCompany: 1, withProfessionalAddress: 1 }
const answerParams = { withNeed: 1, withProject: 1, withApplicantCompany: 1, withQuotation: 1, withProfessionalAddress: 1, withCurrency: 1 }
const teamParams = { withUsers: 1, withProfessionalAddress: 1 }
const feedbackParams = { userId: null, withProject: 1, withNeed: 1, withAnswer: 1 }
const answerFeedbackParams = { withProject: 1, withNeed: 1, withAnswer: 1 }
const productParams = { withCompany: 1 }
const expertParams = { withCompany: 1 }
const channelParams = { withProject: 1, withNeed: 1, withReason: 1 }

const extraParams = {
  [EntityTypes.Retailer]: userExtraParams,
  [EntityTypes.Applicant]: userExtraParams,
  [EntityTypes.Collaborator]: { ...userExtraParams, countProducts: 1 },
  [EntityTypes.Facilitator]: userExtraParams,
  [EntityTypes.Answer]: answerParams,
  [EntityTypes.Team]: teamParams,
  [EntityTypes.Product]: productParams,
  [EntityTypes.Feedback]: feedbackParams,
  [EntityTypes.AnswerFeedback]: answerFeedbackParams,
  [EntityTypes.Expert]: expertParams,
  [EntityTypes.Channel]: channelParams
}

export default {
  getById (entityType, id, params) {
    return axios
      .get(`${entityType.description.toLowerCase()}s/${id}`, {
        params
      })
  },
  update (entityType, id, data) {
    // multipart and patch doesn't work
    const url = `${entityType.description.toLowerCase()}s/${id}`
    data._method = 'patch'
    const obj = jsonToFormData(data)
    return axios.post(url, obj.data, obj.config)
  },
  create (entityType, data) {
    const url = `${entityType.description.toLowerCase()}s`
    const obj = jsonToFormData(data)
    return axios.post(url, obj.data, obj.config)
  },
  delete (entityType, id) {
    return axios.delete(`${entityType.description.toLowerCase()}s/${id}`)
  },
  async get (entityType, params, endpointParams, specificEndpoint = null) {
    let endpoint = specificEndpoint ?? endpoints[entityType]
    const extraParamsForType = extraParams[entityType]
    const extraParamsForTypeKeys = _.keys(extraParamsForType)
    _.each(endpointParams, (val, key) => {
      endpoint = endpoint.replace(`{${key}}`, val)
      if (_.indexOf(extraParamsForTypeKeys, key) !== -1) {
        extraParamsForType[key] = val
      }
    })
    const allParams = Object.assign({}, params, extraParamsForType || {})
    return (await axios.get(endpoint, {
      params: objectRemoveEmpty.removeEmpty(allParams),
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
    }))?.data ?? null
  }
}
