<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <v-label
      v-if="fixedLabel"
      :dark="fixedLabelDark"
    >
      {{ displayLabel }}
    </v-label>
    <v-autocomplete
      :ref="field.key"
      v-model="iValue"
      v-bind="$attrs"
      :attach="attach"
      :background-color="backgroundColor"
      :clear-icon="'far fa-times-circle'"
      :clearable="clearable"
      :dense="dense"
      :disabled="disabled"
      :error="errors.length > 0 || false"
      :error-messages="errors"
      :hide-details="errors.length === 0 || !validation"
      :item-text="itemText"
      :item-value="itemValue"
      :items="options"
      :label="(!fixedLabel && displayLabel) || ''"
      :loading="isLoading"
      :multiple="multiple"
      :outlined="outlined"
      :search-input.sync="search"
      :return-object="returnObject"
      v-on="$listeners"
      @change="search = ''"
    >
      <template
        v-if="customItem"
        #item="data"
      >
        <slot
          name="item"
          v-bind="data"
        />
      </template>
      <template
        v-if="customSelection"
        #selection="data"
      >
        <slot
          name="selection"
          v-bind="data"
        />
      </template>
      <template
        v-else-if="multiple"
        #selection="data"
      >
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close-icon="far fa-times-circle"
          :close="!disabled"
          small
          @click:close="remove(data.item)"
        >
          <span class="text-truncate">{{ getItemText(data.item) }}</span>
        </v-chip>
      </template>
    </v-autocomplete>
  </validation-provider>
</template>

<script>
import dot from 'dot-object'
import { useFieldStore } from '@/compositions/fieldStore'
import { useValidation } from '@/compositions/validation'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'FieldAutocomplete',
  components: {
    ValidationProvider
  },
  props: {
    attach: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    customItem: Boolean,
    customSelection: Boolean,
    dense: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    field: {
      type: Object,
      required: true
    },
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    items: {
      type: Array,
      default: null
    },
    itemText: {
      type: [String, Function],
      default: 'text'
    },
    itemValue: {
      type: [String, Function],
      default: 'value'
    },
    label: {
      type: String,
      default: null
    },
    loading: {
      type: [String, Boolean],
      default: null
    },
    multiple: Boolean,
    outlined: {
      type: Boolean,
      default: true
    },
    remote: {
      type: Object,
      default: null
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    storeKey: {
      type: String,
      default: null
    },
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, Array, Object],
      default: null
    },
    vid: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { store, iStoreKey } = useFieldStore(props)
    const { iRules, displayLabel } = useValidation(props)

    return { store, iStoreKey, iRules, displayLabel }
  },
  data () {
    return {
      fetching: false,
      search: null,
      asyncItems: [],
      toAdd: [],
      pagination: {
        page: null,
        lastPage: null,
        perPage: null
      }
    }
  },
  computed: {
    isLastPage () {
      return !this.pagination.page || this.pagination.lastPage === this.pagination.page
    },
    isAsync () {
      return this.remote ?? null
    },
    isLoading () {
      return this.loading || this.fetching
    },
    options () {
      if (this.isAsync) {
        return this.asyncItems
      } else if (this.storeKey) {
        return dot.pick(this.iStoreKey, this.store) ?? []
      } else if (this.items) {
        return this.items
      } else {
        return []
      }
    },
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    search: {
      handler (value, prevVal) {
        if (this.isAsync && value !== undefined && value !== prevVal) {
          this.fetchEntriesDebounced(value)
        }
      }
    }
  },
  async created () {
    if (this.isAsync) {
      if (this.value) {
        if (Array.isArray(this.value)) {
          this.toAdd = [...this.value]
        } else if (typeof this.value === 'object') {
          this.toAdd.push(this.value)
        }
      }
      try {
        await this.fetch()
        if (this.value) {
          this.fetching = true
          await this.checkInitialValue()
        }
      } finally {
        this.fetching = false
      }
    }
  },
  methods: {
    fetch (v) {
      const params = {}

      this.fetching = true
      if (this.remote.params) {
        // eslint-disable-next-line prefer-const
        for (let [key, value] of Object.entries(this.remote.params)) {
          if (key !== 'searchParam') {
            params[key] = value
          }
        }
      }

      if (v && (this.remote?.params?.searchParam)) {
        params[this.remote.params.searchParam] = v
      }

      params.resultsPerPage = this.remote?.params?.maxItems || window.env.AUTOCOMPLETE_MAX_ITEMS

      return this.$axios({
        method: this.remote.method,
        url: this.remote.url,
        params
      }).then(res => {
        const results = res.data.data
        if (Array.isArray(results)) {
          this.asyncItems = [...this.toAdd, ...results]
          this.toAdd = []
          this.pagination.page = res.data.meta.current_page
          this.pagination.lastPage = res.data.meta.last_page
          this.pagination.perPage = res.data.meta.per_page
        } else {
          this.asyncItems = []
        }
      })
        .catch(err => {
          throw new Error(err)
        })
        .finally(() => (this.fetching = false))
    },
    fetchEntriesDebounced (value) {
      this.$debounce(this.fetch, value)
    },
    getItemText (item) {
      if (typeof this.itemText === 'function') {
        return this.itemText(item)
      }
      return item[this.itemText]
    },
    remove (field) {
      this.iValue = this.iValue
        .filter(f => {
          if (this.returnObject) {
            if (typeof this.itemValue === 'function') {
              return this.itemValue(f) !== this.itemValue(field)
            }
            return f[this.itemValue] !== field[this.itemValue]
          }
          if (typeof this.itemValue === 'function') {
            return f !== this.itemText(field)
          }
          return f !== field[this.itemValue]
        })
    },
    getById (id) {
      const url = this.remote.urlFind.replace(`:${this.remote.urlFindKey}`, id)
      return this.$axios({
        method: 'GET',
        url,
        params: this.remote.urlFindParams
      }).then(res => res.data.data)
        .catch(err => {
          throw new Error(err)
        })
    },
    async getNotFoundItem (v) {
      if (this.remote.urlFind && this.remote.urlFindKey) {
        const id = v.id || v
        const found = this.asyncItems
          .find(f => dot.pick(this.remote.urlFindKey, f) === id)
        if (!found) {
          const item = await this.getById(id)
          if (item) {
            const prepend = Array.isArray(item) ? item : [item]
            this.asyncItems = [
              ...prepend,
              ...this.asyncItems
            ]
          }
        }
      }
    },
    checkInitialValue () {
      if (this.multiple) {
        this.value.forEach(v => {
          this.getNotFoundItem(v)
        })
      } else {
        this.getNotFoundItem(this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-autocomplete) {
  .v-input__icon--clear .v-icon--link {
    font-size: 1.1rem !important;
  }

  .v-chip {
    margin-top: 5px;
  }
}
</style>
