<template>
  <field-autocomplete
    v-model="select"
    v-bind="$attrs"
    :field="field"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :loading="loading"
    :multiple="multiple"
    :search-input.sync="search"
    hide-selected
    return-object
    cache-items
  />
</template>

<script>
import FieldAutocomplete from '../FieldAutocomplete'
import PimFetcher from '@/services/fetchers/Pim'
import { usePimStore } from '@/stores/pim'
import { mapActions, mapState } from 'pinia'

export default {
  name: 'FieldPimReference',
  components: {
    FieldAutocomplete
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    itemText: {
      type: [String, Function],
      default: 'label'
    },
    itemValue: {
      type: [String, Function],
      default: 'id'
    },
    itemsPerPage: {
      type: Number,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: true
    },
    orderBy: {
      type: Object,
      default: null
    },
    value: {
      type: [Array, String],
      default: null
    }
  },
  data () {
    return {
      items: [],
      loading: false,
      search: null,
      select: null
    }
  },
  computed: {
    key () {
      return this.field.pimKey.split('::')[1]
    },
    slug () {
      return this.field.pimSlug
    },
    ...mapState(usePimStore, {
      references: 'getReferences'
    })
  },
  watch: {
    value (val) {
      if (!val) {
        this.select = null
      }
    },
    select (val) {
      this.emit(val)
    },
    search (val, prevVal) {
      if (!val || val === prevVal || val.length < 2) return
      this.fetchEntriesDebounced(val)
    }
  },
  async created () {
    const { key, slug, value, orderBy } = this

    if (value) {
      try {
        this.loading = true
        await this.fetchReferences({ key, slug, values: Array.isArray(value) ? value : [value] })
      } finally {
        this.loading = true
      }
      this.setSelectValue()
    }
    try {
      const resp = (await PimFetcher.search(this.slug, {
        key,
        orderBy,
        resultsPerPage: this.itemsPerPage
      })).data.data
      this.items = [...resp, ...this.references?.filter(r => r.key === key) ?? []]
    } finally {
      this.loading = false
    }
  },
  methods: {
    setSelectValue () {
      const { key, value } = this
      let keysToKeep = []
      if (this.multiple) {
        keysToKeep = value.filter(v => this.references?.some(r => r.key === key && r.value === v)) ?? false
      }

      if (this.references) {
        this.select = this.multiple
          ? keysToKeep.map(v => this.references.find(r => r.key === key && r.value === v))
          : this.references.find(r => r.key === key && r.value === value)
      }
    },
    emit (selected) {
      if (selected) {
        if (this.multiple) {
          this.$emit('input', selected.map(m => m.value))
        } else {
          this.$emit('input', selected.value)
        }
      } else {
        this.$emit('input', null)
      }
    },
    async fetch (value) {
      const { key, orderBy } = this
      try {
        this.loading = true
        const resp = (await PimFetcher.search(this.slug, { key, orderBy, label: value })).data.data
        this.items = resp
      } finally {
        this.loading = false
      }
    },
    fetchEntriesDebounced (value) {
      this.$debounce(this.fetch, value)
    },
    ...mapActions(usePimStore, {
      fetchReferences: 'fetchReferences'
    })
  }
}
</script>
