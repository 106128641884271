import ResourceFetcher from '@/services/fetchers/Resource'
import AnswerFetcher from '@/services/fetchers/Answer'
import EntityTypes from '@/constants/EntityTypes'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAnswerDetailStore = defineStore('answerDetail', () => {
  const answer = ref(null)
  const fetchingActors = ref(false)

  const getAnswer = computed(() => answer.value)
  const getActors = computed(() => getAnswer.value.actors)

  async function fetchAnswer ({ answerId }) {
    const params = {
      withNeed: 1,
      withStatusUpdaterInfo: 1,
      withProject: 1,
      withCompanyApplicant: 1,
      withQuotation: 1,
      withFeedback: 1,
      withInvoice: 1,
      withReason: 1,
      withReasons: 1
    }
    const result = (await ResourceFetcher.getById(
      EntityTypes.Answer,
      answerId,
      params
    )).data.data
    answer.value = result
  }
  async function fetchActors ({ answerId, needId, projectId }) {
    if (!fetchingActors.value) {
      try {
        fetchingActors.value = true
        const actors = await AnswerFetcher.getUsers(answerId, needId, projectId)
        answer.value = { ...answer.value, actors }
      } finally {
        fetchingActors.value = false
      }
    }
  }
  function updateAnswer (payload) {
    answer.value = {
      ...answer.value,
      ...payload
    }
  }

  return {
    answer,
    getAnswer,
    getActors,
    fetchAnswer,
    fetchActors,
    updateAnswer
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useAnswerDetailStore, import.meta.webpackHot))
}
