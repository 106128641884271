import axios from 'axios'
import router from '@/router'
import moment from '@/plugins/moment'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'

const instance = axios.create({
  baseURL: window.env.VUE_APP_WORKPLACE_CORE_API_URL,
  headers: {
    'content-type': 'application/json; charset=utf-8'
  }
})

const excludedRoutes = [
  {
    uri: '/assets',
    methods: ['get'],
    status: [404]
  },
  {
    uri: '/quotation-settings',
    methods: ['get'],
    status: [404]
  },
  {
    uri: '/talk-channels',
    methods: ['get', 'post'],
    status: [404, 500]
  }
]

const publicApiRoutes = [
  '/auth/login',
  '/auth/forgotten-password',
  '/auth/reset-password',
  '/auth/reset-password/password-policy'
]

function shouldIntercept (error) {
  let intercept = true
  excludedRoutes.forEach(f => {
    const checkUri = error.response.config.url.indexOf(f.uri) !== -1
    const checkMethods = f.methods.length === 0 || f.methods.some(s => s === error.response.config.method)
    const checkStatus = f.status.some(s => s === error.response.status)
    if (checkMethods && checkUri && checkStatus) {
      intercept = false
    }
  })
  return intercept
}

function redirectError (name, message, extra) {
  router.push({
    name,
    params:
    {
      message,
      path: extra?.path,
      error: extra?.error
    }
  })
}

instance.interceptors.request.use(function (config) {
  if (!publicApiRoutes.some(s => s === config.url)) {
    const authStore = useAuthStore()
    const { accessToken, accessTokenExpiresAt } = storeToRefs(authStore)
    const isExpired = accessTokenExpiresAt.value ? moment().isAfter(accessTokenExpiresAt.value) : true

    if (isExpired) {
      authStore.logout()
      if (router.currentRoute.name !== 'user-login') {
        redirectError('user-login', 'common.error.invalid-token', { path: location.pathname })
        return Promise.reject(new Error('common.error.invalid-token'))
      }
    } else if (accessToken.value) {
      config.headers.Authorization = `Bearer ${accessToken.value}`
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response) {
    if (shouldIntercept(error)) {
      const errorStatus = error.response.status
      if (errorStatus === 401) {
        const authStore = useAuthStore()
        authStore.logout()
        if (router.currentRoute.name !== 'user-login') {
          redirectError('user-login', error.response.data.message, { path: location.pathname })
        }
      } else {
        const errMsg = `error.${error.response.status}`
        switch (errorStatus) {
          case 403:
          case 404:
            redirectError('error', errMsg)
            break
          case 500:
            redirectError('error', errMsg, { error: error.response })
            break
          case 503:
            redirectError('maintenance', errMsg, { path: location.pathname, error: error.response })
            break
        }
      }
    }
    return Promise.reject(error)
  }
})

export default instance
