import moment from '@/plugins/moment'
import axios from '@/plugins/axios'
import socket from '@/services/socket'
import AuthFetcher from '@/services/fetchers/Auth'
import { useUniverseStatusStore } from '@/stores/universeStatus'
import { useNotificationStore } from '@/stores/notification'
import { useUniverseStore } from '@/stores/universe'
import { useProductStore } from '@/stores/product'
import { useAssetsStore } from '@/stores/assets'
import { useNoteStore } from '@/stores/note'
import { useUserStore } from '@/stores/user'
import { usePimStore } from '@/stores/pim'
import { useRefStore } from '@/stores/ref'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null)
  const accessToken = ref(null)
  const accessTokenExpiresAt = ref(null)
  const locale = ref(null)
  const loggedAs = ref(false)

  const getUser = computed(() => user.value)
  const isLoggedAs = computed(() => loggedAs.value)

  async function logAs (payload) {
    const response = await AuthFetcher.logAs(payload)
    loggedAs.value = true
    return loadUser(response)
  }
  async function login (payload) {
    const { username, password, instance } = payload
    const response = await AuthFetcher.login({ username, password })
    return loadUser(response, instance)
  }
  async function loadUser (response, instance) {
    const universeStore = useUniverseStore()
    const universeStatusStore = useUniverseStatusStore()
    const refStore = useRefStore()

    await universeStore.clear()
    await refStore.clear()

    saveTokens({ response })
    await Promise.all([
      refStore.loadRefs(),
      universeStore.loadSettings(),
      universeStore.loadCurrencies()
    ])
    const usr = response.data.data.user
    if (!usr.profiles || loggedAs.value) {
      await universeStatusStore.clear()
      await universeStatusStore.loadUniverseStatuses()
    }
    user.value = usr
    if (usr.locale) {
      switchLocale({ instance, lang: usr.locale })
    }
    return response
  }
  function logout () {
    const universeStore = useUniverseStore()
    const noteStore = useNoteStore()
    const assetsStore = useAssetsStore()
    const userStore = useUserStore()
    const universeStatusStore = useUniverseStatusStore()
    const pimStore = usePimStore()
    const productStore = useProductStore()
    const refStore = useRefStore()
    const notificationStore = useNotificationStore()

    universeStatusStore.clear()
    userStore.clear()
    noteStore.clear()
    assetsStore.clear()
    pimStore.clear()
    productStore.clear()
    refStore.clear()
    universeStore.clear()
    notificationStore.clear()

    clear()

    socket.disconnect()
  }
  function clear () {
    accessToken.value = null
    accessTokenExpiresAt.value = null
    user.value = null
    locale.value = null
    loggedAs.value = false
  }
  async function logAsOrigin () {
    const response = await AuthFetcher.logAsOrigin()
    const result = await loadUser(response)

    loggedAs.value = false

    return result
  }
  function saveTokens (payload) {
    const { response } = payload
    try {
      const params = {
        expiresIn: response.data.data.expires_in - 5,
        accessToken: response.data.data.access_token
      }
      accessToken.value = params.accessToken
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken.value}`

      accessTokenExpiresAt.value = moment().utc().add(params.expiresIn, 'seconds').toISOString()
    } catch (e) {
      throw new Error(e)
    }
  }
  function switchLocale ({ instance, lang = null }) {
    if (instance && lang && lang.code.replace('_', '-') !== locale.value) {
      locale.value = lang.code.replace('_', '-')

      const currentLocale = locale.value
      instance.$i18n.locale = currentLocale
      instance.$vuetify.locale = currentLocale
      instance.$vuetify.lang.current = currentLocale
      instance.$utils.moment.locale(currentLocale)
      instance.$utils.numeral.locale(currentLocale)
    }
  }
  function updateUser (payload) {
    user.value = { ...user.value, ...payload }
  }

  return {
    user,
    accessToken,
    accessTokenExpiresAt,
    locale,
    loggedAs,

    getUser,
    isLoggedAs,

    clear,
    logAs,
    login,
    loadUser,
    logout,
    logAsOrigin,
    saveTokens,
    switchLocale,
    updateUser
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useAuthStore, import.meta.webpackHot))
}
