<script setup>
import AccountMenu from './AccountMenu'
import initials from '@/services/initials'
import AppBar from '@/components/AppBar'
import NotificationBadge from '@/components/NotificationBadge'
import UserMenu from '@/components/UserMenu'
import { useLayoutMenu } from '@/compositions/layoutMenu'
import { useUniverseStore } from '@/stores/universe'
import { computed, getCurrentInstance } from 'vue'
import { storeToRefs } from 'pinia'
import _ from 'lodash'

const root = getCurrentInstance().proxy

const emits = defineEmits(['input'])

const props = defineProps({
  user: {
    type: Object,
    default: null
  },
  items: {
    type: Array,
    default: () => ([])
  },
  profile: {
    type: Object,
    required: true
  },
  value: Boolean
})

const universeStore = useUniverseStore()

const { settings } = storeToRefs(universeStore)

const { isActiveItem } = useLayoutMenu()

const userAvatar = computed(() => props.user?.avatar?.urlCached ?? null)
const userInitials = computed(() => {
  if (!props.user) {
    return null
  }
  return initials(props.user.firstName, props.user.lastName, props.user.displayName, props.user.email)
})
const iValue = computed({
  get: () => props.value,
  set: val => emits('input', val)
})
const iItems = computed(() => {
  return props.items?.map(m => ({
    to: `/${m.destination !== '/' ? m.destination : ''}`,
    title: root.$t(`ref-menu.${m.slug}`)
  })) ?? []
})
const logoFull = computed(() => settings.value?.theme?.logoFullUrl ?? null)

function isActive (menu) {
  return isActiveItem(
    root.$route.path,
    menu.to, _.map(iItems.value, i => i.to)
  )
}
</script>

<template>
  <app-bar
    v-model="iValue"
    :items="iItems"
    :mobile="$vuetify.breakpoint.mobile"
  >
    <template #logo>
      <v-img
        :src="logoFull"
        :max-height="40"
        class="mx-2"
        width="190px"
        contain
      />
    </template>
    <template
      v-if="!$vuetify.breakpoint.mobile"
      #navigation
    >
      <v-toolbar-items>
        <v-btn
          v-for="item in iItems"
          :key="item.name"
          :to="item.to"
          :ripple="false"
          :class="{ 'v-btn--active': isActive(item) }"
          plain
          text
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </template>
    <template #actions>
      <notification-badge
        :user-id="profile.userProfileId"
        @open-notification="$emit('click:notification')"
      />
      <user-menu>
        <template #avatar>
          <avatar
            :size="40"
            :url="userAvatar"
            :initials="userInitials"
          />
        </template>
        <template #menu-items>
          <account-menu />
        </template>
      </user-menu>
    </template>
  </app-bar>
</template>

<style lang="scss" scoped>
:deep(.v-toolbar__items) {
  a.v-btn {
    color: var(--v-night-base) !important;

    &--active {
      color: var(--v-primary-base) !important;
      border-bottom: 3px solid var(--v-primary-base);
    }
  }

  a.v-btn:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
