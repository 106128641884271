import Vue from 'vue'
import { useUniverseStore } from '@/stores/universe'

Vue.filter('money', (value, currencyId) => {
  const universeStore = useUniverseStore()
  const currencies = universeStore.getCurrencies
  if (!currencyId) {
    currencyId = universeStore.getSettingByPath('currency', null)
  }
  const currency = currencies.find((c) => c.id === currencyId)
  return `${parseFloat(value).toLocaleString(undefined,
    { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: currency.iso }
  )}`
})
