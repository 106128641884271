<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <v-label
      v-if="fixedLabel && displayLabel"
      :dark="fixedLabelDark"
    >
      {{ displayLabel }}
    </v-label>
    <v-text-field
      ref="inputRef"
      v-bind="$attrs"
      :value="formattedValue"
      :background-color="backgroundColor"
      :dense="dense"
      :error-messages="errors"
      :error="validation && !!errors.length"
      :hide-details="!validation || !errors.length"
      :label="inputLabel"
      :outlined="outlined"
      :prefix="$t(prefix)"
    />
  </validation-provider>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { ValidationProvider } from 'vee-validate'
import { useValidation } from '@/compositions/validation'
import { watch } from 'vue'

export default {
  name: 'FieldCurrency',
  components: {
    ValidationProvider
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    currency: {
      type: String,
      default: 'EUR'
    },
    dense: {
      type: Boolean,
      default: true
    },
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    label: {
      type: String,
      default: null
    },
    locale: {
      type: String,
      default: 'fr-FR'
    },
    outlined: {
      type: Boolean,
      default: true
    },
    precision: {
      type: Number,
      default: 2
    },
    prefix: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    vid: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)
    const { inputRef, formattedValue, setValue } = useCurrencyInput({
      currency: props.currency,
      locale: props.locale.replace('_', '-'),
      precision: props.precision
    })

    watch(() => props.value, value => { setValue(value) })

    return { inputRef, formattedValue, iRules, displayLabel }
  },
  computed: {
    inputLabel () {
      return (!this.fixedLabel && this.displayLabel) || null
    }
  }
}
</script>
