import UniverseFetcher from '@/services/fetchers/Universe'
import Vuetify from '@/plugins/vuetify'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import dot from 'dot-object'
import _ from 'lodash'

export const useUniverseStore = defineStore('universe', () => {
  const settings = ref(null)
  const dictionary = ref(null)
  const teams = ref([])
  const collaborators = ref([])
  const facilitators = ref([])
  const dispatchRules = ref([])
  const decisions = ref(null)
  const taxRates = ref(null)
  const currencies = ref([])

  const getSettings = computed(() => settings.value)
  const getSettingByPath = computed(() => {
    return (path, defaultValue) => dot.pick(path, settings.value) || defaultValue
  })
  const getDictionary = computed(() => {
    return (key) => _.filter(dictionary.value, { key })
  })
  const getDispatchRules = computed(() => dispatchRules.value)
  const getTeams = computed(() => teams.value)
  const getCurrencies = computed(() => currencies.value)
  const getTaxRates = computed(() => taxRates.value)
  const getDecisions = computed(() => {
    return (version) => decisions.value?.find(
      d => d.version === version)?.decisions ?? null
  })
  const getFacilitators = computed(() => facilitators.value)
  const getCollaborators = computed(() => collaborators.value)

  function clear () {
    collaborators.value = []
    currencies.value = []
    decisions.value = null
    dictionary.value = null
    dispatchRules.value = []
    facilitators.value = []
    settings.value = null
    taxRates.value = null
    teams.value = []
  }
  function findProjectDamAssetIdByTypeId (typeId) {
    return getDictionary.value('project-type').find(elem => elem.id === typeId)?.extra?.avatarDamAssetId
  }
  function updateSetting (payload) {
    const keys = payload.key.split('.')
    let currentObj = { ...settings.value }
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i]
      if (!currentObj[key]) {
        currentObj[key] = {}
      }
      currentObj = currentObj[key]
    }

    currentObj[keys[keys.length - 1]] = payload.value
    settings.value = { ...settings.value, ...currentObj }
  }
  function setTeams (payload) {
    teams.value = payload
  }
  function setCollaborators (payload) {
    collaborators.value = payload
  }
  function setFacilitators (payload) {
    facilitators.value = payload
  }
  function setTheme () {
    const getColor = slug => getSettingByPath?.value('theme', null)?.colors[slug] ?? null
    setTimeout(() => {
      Vuetify.framework.theme.themes.light = {
        ...Vuetify.framework.theme.themes.light,
        primary: getColor('primary') ?? '#3C5FFF',
        action: getColor('action') ?? '#3C5FFF',
        secondary: getColor('secondary') ?? '#DDE3FF',
        accent: getColor('accent') ?? '#F4F6FF',
        error: getColor('error') ?? '#D10B34',
        info: getColor('info') ?? '#F5F5F5',
        success: getColor('success') ?? '#169329',
        warning: getColor('warning') ?? '#BF4A25',
        footer: getColor('footer') ?? '#FFFFFF',
        anchor: getColor('anchor') ?? '#343539',
        cloud: getColor('cloud') ?? '#6A6C72',
        night: getColor('night') ?? '#343539',
        coral: getColor('coral') ?? '#FC6181',
        wisp: getColor('wisp') ?? '#F8EAE5',
        dew: getColor('dew') ?? '#E7F4E9',
        amour: getColor('amour') ?? '#FBECEF',
        ghost: getColor('ghost') ?? '#F4F6FF',
        mustard: getColor('mustard') ?? '#FED74D',
        gin: getColor('gin') ?? '#FFF8DF'
      }
    }, 200)
  }
  async function loadCurrencies () {
    currencies.value = await UniverseFetcher.getCurrencies()
  }
  async function loadAnonymousSettings () {
    try {
      const response = await UniverseFetcher.getAnonymousUniverseSettings()
      const values = {}

      response.data.data.forEach(obj => {
        values[obj.key] = obj.value
      })
      dot.object(values)
      settings.value = values
      setTheme()
    } catch (error) {
      throw new Error(error)
    }
  }
  async function loadSettings () {
    try {
      const response = await UniverseFetcher.getUniverse()
      const values = {}

      response[0].data.data.forEach(obj => {
        values[obj.key] = obj.value
      })
      dot.object(values)
      settings.value = values
      dictionary.value = response[1].data.data
      dispatchRules.value = response[2].data.data
      setTheme()
    } catch (error) {
      throw new Error(error)
    }
  }
  async function loadTaxRates (force = false) {
    try {
      if (!taxRates.value || force) {
        taxRates.value = (await UniverseFetcher.getUniverseTaxRates()).data.data
      }
    } catch (error) {
      throw new Error(error)
    }
  }
  async function loadDecisions (version) {
    const versionFound = decisions.value?.find(f => f.version === version) ?? null
    if (!versionFound) {
      try {
        const resp = await UniverseFetcher.getDecisions(version)
        const prevDecisions = decisions.value ?? []
        decisions.value = _.uniqBy([
          ...prevDecisions,
          { version, decisions: resp.data.data }
        ], u => u.version)
      } catch (error) {
        throw new Error(error)
      }
    }
  }

  return {
    settings,
    dictionary,
    teams,
    collaborators,
    facilitators,
    dispatchRules,
    decisions,
    taxRates,
    currencies,

    getSettings,
    getSettingByPath,
    getDictionary,
    getDispatchRules,
    getTeams,
    getCurrencies,
    getTaxRates,
    getDecisions,
    getFacilitators,
    getCollaborators,

    clear,
    findProjectDamAssetIdByTypeId,
    updateSetting,
    setTeams,
    setCollaborators,
    setFacilitators,
    setTheme,
    loadCurrencies,
    loadAnonymousSettings,
    loadSettings,
    loadTaxRates,
    loadDecisions
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useUniverseStore, import.meta.webpackHot))
}
