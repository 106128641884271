<template>
  <field-autocomplete
    v-model="select"
    v-bind="$attrs"
    :field="field"
    :items="items"
    :item-text="i => i"
    :item-value="i => i"
    :loading="loading"
    :multiple="multiple"
    :return-object="false"
    :search-input.sync="search"
    hide-selected
    cache-items
  />
</template>

<script>
import FieldAutocomplete from '../FieldAutocomplete'

export default {
  name: 'FieldGooglePlace',
  components: {
    FieldAutocomplete
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Array, String],
      default: null
    }
  },
  data () {
    return {
      items: [],
      loading: false,
      search: null,
      select: null
    }
  },
  watch: {
    value (val) {
      if (!val) {
        this.select = null
      }
    },
    select (val) {
      this.emit(val)
    },
    search (val, prevVal) {
      if (!val || val === prevVal || val.length < 2) return
      this.fetchEntriesDebounced(val)
    }
  },
  async created () {
    if (this.value) {
      this.setSelectValue()
    }
  },
  methods: {
    setSelectValue () {
      if (this.multiple) {
        this.select = this.value
        this.items = this.value
      }
    },
    emit (selected) {
      if (selected) {
        this.$emit('input', selected)
      } else {
        this.$emit('input', null)
      }
    },
    async fetch (value) {
      try {
        this.loading = true
        const resp = (await this.$axios({
          method: 'GET',
          url: '/geo/autocomplete',
          params: {
            search: value
          }
        })).data.data
        this.items = resp.map(m => m.location)
      } finally {
        this.loading = false
      }
    },
    fetchEntriesDebounced (value) {
      this.$debounce(this.fetch, value)
    }
  }
}
</script>
