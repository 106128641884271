<script setup>
import DialogConfirm from '@/components/Dialogs/DialogConfirm'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed, getCurrentInstance, ref } from 'vue'
import _ from 'lodash'

const root = getCurrentInstance().proxy

const authStore = useAuthStore()
const userStore = useUserStore()

const { menuItems } = storeToRefs(userStore)

const logoutDialog = ref(false)

const items = computed(() => {
  const arr = []

  arr.push({
    icon: 'fa-user',
    title: 'common.my-account',
    dataTest: 'btn-my-account',
    click: () => root.$router.push({ name: 'account' })
  })

  if (menuItems.value.user) {
    _.each(menuItems.value.user, item => {
      arr.push({
        icon: item.icon,
        title: root.$t(`header.${item.slug}`),
        click: () => root.$router.push({ path: `/${item.destination}` })
      })
    })
  }

  arr.push({
    icon: 'fa-sign-out-alt',
    title: 'header.user.choice.logout',
    click: e => {
      e.stopPropagation()
      logoutDialog.value = true
    }
  })

  return arr
})

async function logout () {
  await authStore.logout()
  root.$router.push({ name: 'user-login' })
}
</script>

<template>
  <div>
    <v-list-item
      v-for="(item, idx) in items"
      :key="idx"
      :data-test="item.dataTest"
      dense
      @click="item.click"
    >
      <v-list-item-icon>
        <v-icon small>
          {{ item.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
    </v-list-item>
    <dialog-confirm
      v-model="logoutDialog"
      :message="$t('logout.popup.text')"
      :cancel-label="$t('cta.logout.ko')"
      :confirm-label="$t('cta.logout.ok')"
      :max-width="590"
      @cancel="logoutDialog = false"
      @confirm="logout()"
    />
  </div>
</template>
