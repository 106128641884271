import axios from '@/plugins/axios'
import jsonToFormData from '@/services/jsonToFormData'
import qs from 'qs'

export default {
  isPublishable (productId, product) {
    return axios.post(`/products/${productId}/is-publishable`, { ...product })
  },
  create (product) {
    return axios.post('/products', product)
  },
  createNews (idProduct, news) {
    const formData = jsonToFormData(news)
    return axios.post(`/products/${idProduct}/news`, formData.data, formData.config)
  },
  delete (productId) {
    return axios.delete(`/products/${productId}`)
  },
  exportSubProducts (productId, productType) {
    return axios.get(`/products/${productId}/sub-products-export/${productType}`)
  },
  get (productId) {
    return axios.get(`/products/${productId}`)
  },
  getUserRetailers (productId, params) {
    return axios.get(`/products/${productId}/users-retailers`, { params })
  },
  getAssociations (productId, associationType, locale) {
    return axios.get(`/products/${productId}/associations`, {
      params: {
        locale,
        type: associationType,
        resultsPerPage: 100
      }
    })
  },
  getSubProductImportDemo (productId, productType) {
    return axios.get(`/products/${productId}/sub-products-import-demo/${productType}`)
  },
  getNews (params) {
    const urlParams = qs.stringify(params, { addQueryPrefix: true })
    return axios.get(`/products/${params.productId}/news${urlParams}`)
  },
  getNewsById ({ productId, newsId, locale }) {
    return axios.get(`/products/${productId}/news/${newsId}?locale=${locale}`)
  },
  getHistory (params) {
    const urlParams = qs.stringify(params, { addQueryPrefix: true })
    return axios.get(`/products/${params.productId}/history${urlParams}`)
  },
  importSubProducts (productId, productType, file) {
    const formData = jsonToFormData(file)
    return axios.post(`/products/${productId}/sub-products-import/${productType}`, { import: file }, formData.config)
  },
  save (productId, product) {
    return axios.patch(`/products/${productId}`, product)
  },
  saveAssociation (productId, association) {
    return axios.patch(`/products/${productId}/associations`, association)
  },
  saveUserRetailers (productId, userRetailerIds) {
    return axios.patch(`/products/${productId}/users-retailers`, { userRetailerIds })
  },
  saveVariants (productId, variants) {
    return axios.patch(`/products/${productId}`, { variants })
  },
  search (params) {
    return axios.get('/products?' + qs.stringify(params))
  },
  searchAssociations (productId, params) {
    const urlParams = qs.stringify(params, { addQueryPrefix: true, skipNulls: true })
    return axios.get(`/products/${productId}/associations${urlParams}`)
  },
  setPublishStatus (productId, refStatusSlug) {
    return axios.post(`/products/${productId}/set-publish-status`, { refStatusSlug })
  },
  updateNews (productId, newsId, payload) {
    payload._method = 'patch'
    const formData = jsonToFormData(payload)
    return axios.post(`/products/${productId}/news/${newsId}`, formData.data, formData.config)
  }
}
