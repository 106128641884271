import jsonToFormData from '@/services/jsonToFormData'
import profiles from '@/services/profiles'
import axios from '@/plugins/axios'
import qs from 'qs'

export default {
  async addActor (answerId, params) {
    return (await axios.post(`answers/${answerId}/actors`, params))
  },
  async deleteActor (answerId, profileType, userId) {
    return await axios.delete(`answers/${answerId}/actors/${profileType}/${userId}`)
  },
  async addChannel (id, name) {
    return (await axios.post(`answers/${id}/talk-channels/${name}/add-me`)).data
  },
  async get (id, params) {
    return (await axios.get(`answers/${id}?${qs.stringify(params)}`)).data
  },
  async getChannels (answerId) {
    return (await axios.get(`answers/${answerId}/talk-channels`)).data
  },
  async getNews (id, params) {
    return (await axios
      .get(`answers/${id}/activities`, { params })).data
  },
  orderBy (projectId, needId, params) {
    return axios
      .get('projects/' + projectId + '/needs/' + needId + '/answers', {
        params: {
          ...params
        }
      })
  },
  create (projectId, needId, data) {
    const obj = jsonToFormData(data)
    return axios.post(`projects/${projectId}/needs/${needId}/answers`, obj.data, obj.config)
  },
  async update (answerId, params) {
    return (await axios.patch(`answers/${answerId}`, params)).data
  },
  async updateForm (answerId, form, params) {
    return (await axios.post(`/answers/${answerId}/form/${form}`, params)).data.data
  },
  async getUsers (answerId, needId, projectId) {
    if (profiles.userIsRetailer()) {
      return (await axios.get(`answers/${answerId}/users`)).data.data
    } else {
      return (await axios.get(`projects/${projectId}/needs/${needId}/answers/${answerId}/users`)).data.data
    }
  }
}
