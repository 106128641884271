<script setup>
import Spinner from '@/components/Spinner'
import getFirstMenuRouteName from '@/services/getFirstMenuRouteName'
import { useMessages } from '@/compositions/messages'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { computed, getCurrentInstance, ref, watch } from 'vue'

const emits = defineEmits(['input'])
const props = defineProps({
  value: Boolean,
  user: {
    type: Object,
    required: true
  }
})

const root = getCurrentInstance().proxy
const { errorMessage, successMessage } = useMessages()
const { logAs, logout } = useAuthStore()
const { setData } = useUserStore()

const cancelPossible = ref(true)

const dialogVisible = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})
const login = computed(() => props.user?.displayName)

watch(dialogVisible, val => {
  if (val) {
    setTimeout(async () => {
      cancelPossible.value = false
      try {
        const response = await logAs(props.user.profile.userProfileId)
        const profile = props.user.profile
        const menuItems = response.data.data.refMenu
        const routeName = getFirstMenuRouteName(menuItems?.main ?? null)
        if (!routeName) {
          await logout()
          location.reload()
        } else {
          await setData({ profile, menuItems })
          successMessage('common.log-as.success')
          root.$router.push({ name: routeName })
        }
      } catch {
        errorMessage('common.error.base')
      } finally {
        dialogVisible.value = false
      }
    }, 2000)
  }
})
</script>

<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="590"
    persistent
  >
    <v-card>
      <v-card-text class="d-flex flex-column align-center pt-1">
        <spinner />
        {{ $t('common.login-as', { login }) }}
      </v-card-text>
      <v-card-actions class="grey justify-center">
        <v-btn
          :disabled="!cancelPossible"
          class="accent"
          color="primary"
          text
          @click="dialogVisible = false"
        >
          {{ $t('common.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
