import Vue from 'vue'
import App from './App'
import cssVars from 'css-vars-ponyfill'

/* Start Load Plugins */
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import './plugins/vee-validate'
import './plugins/vue-async-computed'
import './plugins/vue-meta'
// import './plugins/gtm'
import NewRelic from './plugins/new-relic'
/* End Load Plugins */

import './installUtils'
import './filters'
import './providers'

import router from './router'

import Empty from './layouts/Empty'
import Admin from './layouts/Admin'
import Visitor from './layouts/Visitor'
import Didomi from './components/Didomi'
import Authenticated from './layouts/Authenticated'
import '@/components/Fields/_globals'

import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

NewRelic.load()

Vue.component('EmptyLayout', Empty)
Vue.component('AdminLayout', Admin)
Vue.component('Didomi', Didomi)
Vue.component('VisitorLayout', Visitor)
Vue.component('AuthenticatedLayout', Authenticated)

cssVars({
  updateDOM: true,
  watch: true
})

Vue.config.productionTip = false

new Vue({
  router,
  pinia,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
