import axios from '@/plugins/axios'

export default {
  create (params) {
    return axios.post('/universe-dictionary', params)
  },
  getDecisions (version) {
    return axios.get(`/decisions/${version}`)
  },
  async getCurrencies () {
    const res = await axios.get('/universe-currencies')
    return res.data.data
  },
  async getDispatchRules () {
    try {
      return await axios.get('/project-dispatch-rules')
    } catch (ex) {
      return {
        data: {
          data: []
        }
      }
    }
  },
  getRefList () {
    return axios.get('/universe-dictionary/ref-list')
  },
  getUniverse () {
    return Promise.all([
      this.getUniverseSettings(),
      this.getUniverseDictionary(),
      this.getDispatchRules()
    ])
  },
  getUniverseDictionary () {
    return axios.get('/universe-dictionary')
  },
  getUniverseSetting (id) {
    return axios.get(`/universe-settings/${id}`)
  },
  getAnonymousUniverseSettings () {
    return axios.get('/universe-settings/anonymous')
  },
  getUniverseSettings (params) {
    return axios.get('/universe-settings', { params })
  },
  getUniverseTaxRates () {
    return axios.get('/universe-tax-rates')
  },
  updateDictionary (id, params) {
    return axios.patch(`/universe-dictionary/${id}`, params)
  },
  updateUniverseSetting (id, params) {
    return axios.patch(`/universe-settings/${id}`, params)
  }
}
