import i18n from '@/plugins/vue-i18n'
import refStatuses from '@/services/refStatuses'
import { useUniverseStatusStore } from '@/stores/universeStatus'
import { useUniverseStore } from '@/stores/universe'
import _ from 'lodash'

export default function (listKey, whitelist) {
  if (listKey) {
    let dictionary
    const universeStore = useUniverseStore()
    if (listKey === 'Project::Status' || listKey === 'Answer::Status') {
      const entityType = listKey.split('::')[0]
      const universeStatusStore = useUniverseStatusStore()

      dictionary = universeStatusStore.getUniverseStatuses(entityType)
      return _.map(dictionary, i => ({
        value: i.id,
        text: i18n.t(`universe-status.${i.slug}`)
      }))
    } else if (listKey === 'Project::Team') {
      dictionary = universeStore.getTeams
      return _.map(dictionary, ({ id, name }) => ({
        value: id,
        text: name
      }))
    } else if (listKey === 'dispatchRules') {
      dictionary = universeStore.getDispatchRules
      return _.map(dictionary, ({ label, id }) => ({
        text: label,
        value: id
      }))
    } else if (listKey === 'status') {
      const wl = whitelist || ['active', 'inactive']
      return _.map(
        refStatuses.refStatuses().filter(s => wl.indexOf(s.slug) > -1),
        ({ slug, id }) => ({ text: i18n.t(`ref-status.${slug}`), value: id })
      )
    } else {
      const key = _.map(listKey.split('::'), p => p.charAt(0).toLowerCase() + p.slice(1)).join('-')
      dictionary = universeStore.getDictionary(key)
      return _.map(dictionary, i => ({
        value: key === 'project-eventRecurrence' ? i.value : i.id,
        text: i18n.t(`universe-dictionary.${i.value}`),
        extra: i.extra
      }))
    }
  }
}
