<script setup>
import Snackbar from '@/components/Snackbar'
import { useLayout } from '@/compositions/layout'
import { useUniverseStore } from '@/stores/universe'
import { useTranslationStore } from '@/stores/translation'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

const universeStore = useUniverseStore()
const translationStore = useTranslationStore()

const { settings } = storeToRefs(universeStore)
const { loading } = storeToRefs(translationStore)
const { language, isMobile } = useLayout()

const countryStyle = computed(() => ({ width: isMobile.value ? '50px' : '150px' }))
const logoFull = computed(() => settings.value?.theme?.logoFullUrl ?? null)
const locales = computed(() => {
  const locales = []
  const supportedLocale = window.env.VUE_APP_I18N_SUPPORTED_LOCALE
  const localesFromEnv = supportedLocale ? supportedLocale.split(',') : []

  localesFromEnv.forEach(locale => locales.push({
    text: `ref-locale.${locale.toLowerCase()}`,
    value: locale
  }))
  return locales
})
</script>

<template>
  <v-app>
    <v-app-bar
      v-if="!loading"
      :clipped-left="!isMobile"
      class="px-5"
      app
      elevation="0"
    >
      <v-toolbar-title>
        <img
          :src="logoFull"
          :alt="logoFull"
        >
      </v-toolbar-title>
      <v-spacer />
      <field-country
        v-model="language"
        background-color="unset"
        :countries="locales"
        :outlined="false"
        :show-selection-text="!isMobile"
        :style="countryStyle"
      />
    </v-app-bar>

    <page-loader v-if="loading" />
    <slot v-else />
    <snackbar />
  </v-app>
</template>

<style lang="scss" scoped>
:deep(.v-toolbar__title) {
  img {
    max-height: 24px;
  }
}
</style>
