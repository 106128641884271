import { computed, ref, onBeforeMount, watch, nextTick, getCurrentInstance } from 'vue'
import { useNoteStore } from '@/stores/note'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import { useUniverseStore } from '@/stores/universe'
import socket from '@/services/socket'
import { storeToRefs } from 'pinia'

export function useLayout () {
  const root = getCurrentInstance().proxy
  const appStore = useAppStore()
  const noteStore = useNoteStore()
  const userStore = useUserStore()
  const authStore = useAuthStore()
  const universeStore = useUniverseStore()

  const { accessToken, user } = storeToRefs(authStore)

  const { $i18n, $route, $vuetify } = root
  const drawer = ref(false)
  const drawerRight = ref(false)
  const drawerRightExpanded = ref(false)
  const language = ref($i18n.locale)
  const showNotification = ref(false)
  const themeIsReady = ref(false)

  const appIsReady = computed(() => appStore.appIsReady)
  const profile = computed(() => userStore.getProfile)
  const isMobile = computed(() => $vuetify.breakpoint.mdAndDown)
  const theme = computed(() => !themeIsReady.value ? 'light' : $vuetify?.theme?.dark ? 'dark' : 'light')
  const oTheme = computed(() => $vuetify?.theme?.themes[theme.value] ?? null)
  const footerColor = computed(() => themeIsReady.value ? oTheme.value.footer : 'white')
  const showNote = computed(() => noteStore.getShowNote)
  const widthDrawerRight = computed(() => drawerRightExpanded.value ? '100%' : 650)

  const changeLocale = async () => {
    await authStore.switchLocale({
      instance: root,
      lang: {
        code: language.value
      }
    })
  }
  const closeDrawerRight = () => {
    drawerRight.value = false
    noteStore.setShowNote(false)
    showNotification.value = false
    drawerRightExpanded.value = false
  }
  const drawerRightExpand = () => {
    drawerRightExpanded.value = !drawerRightExpanded.value
  }
  const drawRightIsUp = (value) => {
    if (!value) {
      noteStore.setShowNote(value)
      showNotification.value = value
    }
  }
  const initSocket = async () => {
    if (profile.value) {
      await socket.init(user.value.id, accessToken.value)
    }
  }
  const openNotification = async () => {
    drawerRight.value = true
    showNotification.value = false
    await nextTick()
    showNotification.value = true
  }
  const setThemeAndMayOpenNotification = async () => {
    await universeStore.setTheme()
    setTimeout(() => {
      themeIsReady.value = true
      if ($route.query.openNotificationsDialog === 'true') {
        openNotification()
      }
    }, 200)
  }

  onBeforeMount(() => {
    // Apply theme settings
    changeLocale()
    drawer.value = appIsReady.value && !isMobile.value
    if (appIsReady.value) {
      setThemeAndMayOpenNotification()
    }
    initSocket()
    if (profile.value) {
      userStore.initConnectedUsers()
    }
  })

  watch(appIsReady, appIsReady => {
    nextTick(() => {
      drawer.value = appIsReady.value && !isMobile.value
      if (appIsReady.value) {
        setThemeAndMayOpenNotification()
      }
    })
  })
  watch(isMobile, val => {
    nextTick(() => { drawer.value = appIsReady.value && !val })
  })
  watch(language, changeLocale)
  watch(profile, initSocket)
  watch(showNote, val => { drawerRight.value = val })

  return {
    appIsReady,
    drawer,
    drawerRight,
    drawerRightExpanded,
    footerColor,
    isMobile,
    language,
    showNote,
    showNotification,
    widthDrawerRight,
    closeDrawerRight,
    drawerRightExpand,
    drawRightIsUp,
    openNotification
  }
}
