
<template>
  <component :is="layout">
    <router-view />
    <didomi
      v-if="didomiKey"
      :api-key="didomiKey"
      :config="config"
    />
  </component>
</template>

<script>
import { useUniverseStore } from '@/stores/universe'
import _ from 'lodash'

const defaultLayout = 'authenticated'

export default {
  name: 'App',
  data () {
    return {
      didomiKey: window.env.VUE_APP_DIDOMI_KEY
    }
  },
  metaInfo () {
    const universeStore = useUniverseStore()
    const faviconUrl = universeStore.getSettingByPath('theme.faviconUrl', null)
    const appName = this.$t('common.app-name') !== 'common.app-name' ? this.$t('common.app-name') : window.env.VUE_APP_NAME

    return {
      title: appName,
      link: [
        { rel: 'icon', href: faviconUrl, type: 'image/png' }
      ],
      meta: _.map(this.layer, l => {
        return {
          vmid: l.meta,
          name: l.meta,
          content: l.value
        }
      })
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    },
    layer () {
      const appName = this.$t('common.app-name') !== 'common.app-name'
        ? this.$t('common.app-name')
        : window.env.VUE_APP_NAME

      return [{
        key: 'name',
        meta: 'ipd:siteName',
        value: appName
      }, {
        key: 'cmp',
        meta: 'ipd:cmp',
        value: 'Sébastien OLSZANSKI'
      }]
    },
    config () {
      const app = {}
      _.each(this.layer, l => {
        app[l.key] = l.value
      })

      return {
        app
      }
    }
  }
}
</script>
