import qs from 'qs'
import axios from '@/plugins/axios'
import Vuetify from '@/plugins/vuetify'

const getRefLocaleCode = () => {
  return Vuetify.framework.lang.current.replace('-', '_')
}

export default {
  get (slug, params) {
    return axios
      .get(`/universe-pims/${slug}/universe-pim-references`, {
        params: { refLocaleCode: getRefLocaleCode(), ...params },
        paramsSerializer: p => qs.stringify(p)
      })
  },
  search (slug, params) {
    return axios
      .get(`/universe-pims/${slug}/universe-pim-references/search`, {
        params: Object.assign({}, params, { refLocaleCode: getRefLocaleCode() }),
        paramsSerializer: p => qs.stringify(p)
      })
  }
}
