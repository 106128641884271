<template>
  <div>
    <field-entity-product
      v-if="editable"
      ref="products"
      v-bind="$attrs"
      v-model="products"
      :item-text="itemText"
      :item-value="itemValue"
      :return-object="returnObject"
      multiple
      v-on="$listeners"
    />
    <div v-else>
      <h5 class="my-1">
        {{ $t(translations.counter.title) }}
      </h5>
      <v-progress-circular
        v-if="fetchingProducts"
        :size="30"
        :width="3"
        indeterminate
      />
      <div v-else>
        <v-icon
          size="14"
          color="black"
          class="mr-2"
        >
          fas fa-sitemap
        </v-icon>
        <span>
          {{ $tc(translations.counter.products, value ? value.length : null) }}
        </span>
        <a
          v-if="canWrite && companyId"
          class="goto"
        >
          <span
            class="ml-2 goto-text"
            @click="setEditProducts"
          >{{ $t(translations.editButton) }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldUserRetailerProduct',
  components: {
    FieldEntityProduct: () => import('../FieldEntityProduct')
  },
  props: {
    canWrite: Boolean,
    companyId: {
      type: Number,
      default: null
    },
    itemText: {
      type: String,
      default: 'title'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    fetchingProducts: Boolean,
    hideDetails: {
      type: [Boolean, String],
      default: undefined
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    translations: {
      type: Object,
      default: null
    },
    value: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      editProducts: false
    }
  },
  computed: {
    editable () {
      return !this.disabled && this.companyId && this.editProducts
    },
    products: {
      get () {
        return this.value ?? []
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  },
  watch: {
    companyId: {
      immediate: true,
      handler () {
        if (!this.companyId) {
          this.products = []
          this.editProducts = false
        } else {
          this.products = this.value
        }
      }
    }
  },
  created () {
    this.$off('change')
  },
  methods: {
    setEditProducts () {
      this.editProducts = true
      this.products = this.value
    }
  }
}
</script>
