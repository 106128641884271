<script setup>
import { useLayoutMenu } from '@/compositions/layoutMenu'
import { useUniverseStore } from '@/stores/universe'
import { computed, getCurrentInstance } from 'vue'
import { storeToRefs } from 'pinia'
import _ from 'lodash'

const root = getCurrentInstance().proxy

const emits = defineEmits(['input'])

const props = defineProps({
  items: {
    type: Array,
    default: () => ([])
  },
  value: Boolean
})

const universeStore = useUniverseStore()

const { settings } = storeToRefs(universeStore)
const { isActiveItem } = useLayoutMenu()

const iValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})
const logoFull = computed(() => settings.value?.theme?.logoFullUrl ?? null)

function isActive (menu) {
  return isActiveItem(
    root.$route.path,
    `/${menu.destination}`,
    _.map(props.items, i => `/${i.destination}`)
  )
}
</script>

<template>
  <v-navigation-drawer
    v-model="iValue"
    mini-variant-width="75"
    class="app-menu"
    app
  >
    <v-list>
      <v-list-item>
        <v-img
          :src="logoFull"
          width="190px"
          height="25px"
        />
      </v-list-item>
      <v-list-item
        v-for="(menu, menuIndex) in items"
        :key="`menu-${menuIndex}`"
        :to="`/${menu.destination}`"
        :class="{ 'v-list-item--active': isActive(menu) }"
      >
        <v-list-item-title class="text-capitalize">
          {{ $t(`ref-menu.${menu.slug}`) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
